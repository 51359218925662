<template>
  <section class="conMedicalCalc h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage :headerPage="{ part1: 'Medical', part2: 'calculator' }" />
        </v-col>
      </v-row>
    </v-container>
    <section class="mt-3">
      <v-container>
        <v-row>
          <v-col md="7" cols="12">
            <div class="conCalcSec">
              <v-card class="mx-auto" tile>
                <form class="conFormSubmit">
                  <v-row>
                    <v-col md="6" cols="12">
                      <div class="conSelectOption">
                        <div class="labeledInputs">Age</div>
                        <v-select
                          solo
                          v-model="search.age"
                          :items="ageList"
                          item-text="name"
                          item-value="value"
                          label="Select Age"
                          hide-details="auto"
                          :menu-props="{ bottom: true, offsetY: true }" attach 
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col md="6" cols="12">
                      <div class="conSelectOption">
                        <div class="labeledInputs">Gender</div>

                        <v-select
                          solo
                          v-model="search.gender"
                          :items="genderList"
                          item-text="name"
                          item-value="value"
                          label="Select Gender"
                          hide-details="auto"
                          :menu-props="{ bottom: true, offsetY: true }" attach 
                        ></v-select>
                      </div>
                    </v-col>
                    <v-col md="6" cols="12">
                      <div class="conDefaultInput">
                        <div class="labeledInputs">Weight</div>
                        <div class="conOfInputGroup d-flex">
                          <v-text-field
                            solo
                            v-model="search.weight"
                            label="Weight"
                            hide-details="auto"
                          ></v-text-field>
                          <div class="unitMeasure">Kilo</div>
                        </div>
                      </div>
                    </v-col>
                    <v-col md="6" cols="12">
                      <div class="conDefaultInput">
                        <div class="labeledInputs">Height</div>
                        <div class="conOfInputGroup d-flex">
                          <v-text-field
                            solo
                            v-model="search.height"
                            label="Height"
                            hide-details="auto"
                          ></v-text-field>
                          <div class="unitMeasure">CM</div>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <div>
                        <v-btn
                          class="btnGetResd primaryBtn"
                          @click="onSearch"
                          :disabled="isLoadingSearch"
                          :loading="isLoadingSearch"
                        >
                          GET RESULT =
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </form>
              </v-card>
            </div>
          </v-col>
          <v-col md="5" cols="12">
            <div class="conofcard fill-height">
              <v-card class="mx-auto fill-height" tile>
                <div class="resultContainer fill-height">
                  <div class="headerTxt">you result will be shown here</div>

                  <div class="conOfContentResult">
                    <div class="resultHere"></div>
                    <div class="conBtns text-right">
                      <v-btn class="btnsTxtIcon" text :ripple="false">
                        share
                        <v-icon>mdi-share-variant</v-icon>
                      </v-btn>
                      <v-btn class="btnsTxtIcon" text :ripple="false">
                        Print
                        <v-icon>mdi-printer</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
export default {
  data: () => ({
    isLoadingSearch: false,
    ageList: [
      {
        name: "10-20 years",
        value: 1,
      },
      {
        name: "20-30 years",
        value: 2,
      },
      {
        name: "30-40 years",
        value: 3,
      },
      {
        name: "40-50 years",
        value: 4,
      },
      {
        name: "50-60 years",
        value: 5,
      },
    ],
    genderList: [
      {
        name: "Male",
        value: 1,
      },
      {
        name: "Female",
        value: 2,
      },
    ],
    search: {
      age: null,
      gender: null,
      weight: "",
      height: "",
    },
    BreadcrumbList: [
      {
        text: "Resources",
        disabled: true,
      },
      {
        text: "Medical calculator",
        disabled: false,
      },
    ],
  }),
  components: {
    HeaderPage,
    Breadcrumb,
  },
  methods: {
    onSearch() {},
  },
};
</script>
<style lang="scss" scoped>
@import "./_medical-calculator.scss";
</style>
